type CountryPhoneConfig = {
  dialCode: string;
  minLength: number;
  maxLength: number;
  regex: RegExp;
};

export const COUNTRY_PHONE_CONFIG: Record<string, CountryPhoneConfig> = {
  CO: {
    dialCode: "57",
    minLength: 10,
    maxLength: 10,
    regex: /^[0-9]{10}$/
  }
};

export const convertCountryCodeToDialCode = (countryCode: string): string => {
  return COUNTRY_PHONE_CONFIG[countryCode]?.dialCode || "57";
};

export const getPhoneConfig = (countryCode: string): CountryPhoneConfig => {
  return COUNTRY_PHONE_CONFIG[countryCode] || COUNTRY_PHONE_CONFIG["CO"];
};
