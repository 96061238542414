import { useCallback } from "react";
import { AuthRepository, CandidateRepository } from "@repositories*";
import { TFunction } from "next-i18next";

type Props = {
  t: TFunction;
  setErrorMessage: (msg: string) => void;
  setLoading: (loading: boolean) => void;
};
export const useLogin = ({ t, setErrorMessage, setLoading }: Props) => {
  const handleLogin = useCallback(
    async (email: string, password: string, phone?: string) => {
      if (!email || !password) return setErrorMessage(t("jobOffers:alert.missingCredentials"));
      setLoading(true);
      try {
        const startFlow = await AuthRepository.startFlow();
        if (!startFlow) return setErrorMessage(t("jobOffers:alert.unexpectedError"));
        if (phone) {
          const verifyInformation = await CandidateRepository.verifyEmailAndPhone(email,phone);
          if (!verifyInformation) return setErrorMessage(t("jobOffers:alert.incorrectCredentials"));
        }
        const session = await AuthRepository.loginWithCredentials(email, password);
        if (!session) return setErrorMessage(t("jobOffers:alert.incorrectCredentials"));
        const permissionResponse = await AuthRepository.validatePermission(startFlow?.flowId);
        if (!permissionResponse) return setErrorMessage(t("jobOffers:alert.unexpectedError"));
        const authorizeResponse = await AuthRepository.authorize(startFlow?.flowId);
        if (authorizeResponse.uri === "") return setErrorMessage(t("jobOffers:alert.unexpectedError"));

        const popUp = window.open(authorizeResponse.uri, "authPopup", "width=500,height=600");
        if (!popUp) {
          setErrorMessage(t("jobOffers:alert.popupBlocked"));
          return;
        }
        const url = new URL(window.location.href);
        url.searchParams.set("authSuccess", "true");
        window.history.pushState({}, "", url.toString());
      } catch (error) {
        setErrorMessage(t("jobOffers:alert.unexpectedError"));
      } finally {
        setLoading(false);
      }
    },
    [setErrorMessage, setLoading, t]
  );

  return { handleLogin };
};
