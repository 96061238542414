const LeftTabButton = {
  type: "link" as const,
  href: "/es",
  text: "tabs.lookingForAJob"
};

const RightTabButton = {
  type: "link" as const,
  href: "/es/soluciones",
  text: "tabs.recruiter"
};

const SearchbarText = {
  placeholder: "placeholder.searchbar"
};

export const MAGNETO_HEADER_DRAWER_MENU_SCHEMA = {
  headerDrawerProps: {
    leftTabLink: LeftTabButton,
    rightTabLink: RightTabButton
  },
  listMenuProps: {
    menuList: [
      { isInternal: false, text: "drawerMenu.home", url: "", slug: "inicio" },
      {
        isInternal: true,
        text: "drawerMenu.allJobs",
        url: "jobs",
        slug: "jobs"
      },
      {
        isInternal: true,
        text: "drawerMenu.jobsCity",
        url: "byCity",
        slug: "byCity"
      },
      {
        isInternal: true,
        text: "drawerMenu.jobsCompany",
        url: "byCompany",
        slug: "byCompany"
      },
      {
        isInternal: true,
        text: "drawerMenu.jobsIndustry",
        url: "bySector",
        slug: "bySector"
      },
      {
        isInternal: true,
        text: "drawerMenu.jobsProfessionalPosition",
        url: "byOccupation",
        slug: "byOccupation"
      },
      {
        isInternal: true,
        text: "drawerMenu.jobsProfessionalPositionByCity",
        url: "ocupacol",
        slug: "ocupacol"
      },
      {
        isInternal: true,
        text: "drawerMenu.jobsMostWanted",
        url: "searchHit",
        slug: "searchHit"
      },
      {
        isInternal: false,
        text: "drawerMenu.frequentQuestions",
        url: "/preguntas-frecuentes",
        slug: "preguntas-frecuentes/"
      },
      {
        isInternal: false,
        text: "drawerMenu.articles",
        url: "/blog",
        slug: "blog/"
      }
    ]
  },
  createAccountButton: {
    buttonText: "actionsButtons.createResume"
  },
  loginButton: {
    buttonText: "actionsButtons.login"
  }
};

export const MAGNETO_LOGOUT_HEADER_SCHEMA = {
  leftTabButton: LeftTabButton,
  rightTabButton: RightTabButton,
  searchbar: SearchbarText,
  signInLink: {
    type: "button" as const,
    text: "actionsButtons.login"
  },
  signUpButton: {
    buttonType: "button" as const,
    buttonText: "actionsButtons.createResume"
  }
};

export const MAGNETO_LOGIN_HEADER_SCHEMA = {
  logout: "userMenu.logOut",
  drawerMenu: MAGNETO_HEADER_DRAWER_MENU_SCHEMA.listMenuProps,
  menuUser: [
    {
      title: "userMenu.jobs",
      items: [
        {
          icon: "allJobs",
          text: "userMenu.allJobs",
          slug: "routes:jobs",
          url: "jobs"
        },
        {
          icon: "suggestedJobs",
          text: "userMenu.suggested",
          slug: "/profile/jobs/suggested",
          url: "/profile/jobs/suggested"
        },
        {
          icon: "savedJobs",
          text: "userMenu.saved",
          slug: "/profile/jobs/saved",
          url: "/profile/jobs/saved"
        },
        {
          icon: "inProcess",
          text: "userMenu.processes",
          slug: ["/profile/postulations/active", "/profile/postulations/inactive"],
          url: "/profile/postulations/active"
        }
      ]
    },
    {
      title: "userMenu.myAccount",
      items: [
        {
          icon: "resume",
          text: "userMenu.resume",
          slug: "/profile/resume",
          url: "/profile/resume"
        },
        {
          icon: "alerts",
          text: "userMenu.alerts",
          slug: "/profile/alerts",
          url: "/profile/alerts"
        },
        {
          icon: "settings",
          text: "userMenu.configuration",
          slug: "/profile/resume",
          url: "/profile/resume?tab"
        },
        {
          icon: "help",
          text: "userMenu.help",
          slug: "",
          url: "https://magnetoglobal.zendesk.com/hc/es-419/requests/new"
        }
      ]
    }
  ],
  menuUser1440: [
    {
      items: [
        {
          icon: "help",
          text: "userMenu.help",
          slug: "",
          url: "https://magnetoglobal.zendesk.com/hc/es-419/requests/new"
        }
      ]
    }
  ],
  headerTabListJobs: [
    {
      tabType: "tabTitle" as const,
      url: "#",
      tabText: "userMenu.jobs"
    },
    {
      tabType: "tabOption" as const,
      url: "jobs",
      tabText: "userMenu.allJobs",
      slug: "jobs"
    },
    {
      tabType: "tabOption" as const,
      url: "/profile/jobs/suggested",
      tabText: "userMenu.suggested",
      slug: "/profile/jobs/suggested"
    },
    {
      tabType: "tabOption" as const,
      url: "/profile/jobs/saved",
      tabText: "userMenu.saved",
      slug: "/profile/jobs/saved"
    }
  ],
  headerTabListProcess: [
    {
      tabType: "tabOption" as const,
      url: "/profile/postulations/active",
      tabText: "userMenu.processes",
      slug: ["/profile/postulations/active", "/profile/postulations/inactive"]
    }
  ],
  headerTabListCV: [
    {
      tabType: "tabOption" as const,
      url: "/profile/resume",
      tabText: "userMenu.resume",
      slug: "/profile/resume"
    }
  ]
};
