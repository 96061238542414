import { getPhoneConfig } from "./phoneUtils";

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPhoneNumber = (phoneNumber: string, countryCode: string): boolean => {
  const config = getPhoneConfig(countryCode);
  return config.regex.test(phoneNumber);
};
