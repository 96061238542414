/* eslint-disable camelcase */
import { useState, useEffect, useCallback, useMemo, useContext, useRef } from "react";
import { isValidEmail, isValidPhoneNumber } from "@utilsvalidations/forms/regExpInput.validation";
import { CandidateRepository, MimirRepository } from "@repositories*";
import { TFunction } from "next-i18next";
import { useLogin } from "./useLogin";
import { AuthContext, JobsContext } from "@contextauth.context";
import { notification } from "antd";
import { countryCode } from "@constantsenvironment.constant";
import { convertCountryCodeToDialCode } from "@utilsvalidations/forms/phoneUtils";

type ModalState = {
  email: string;
  password: string;
  loading: boolean;
  isDataAuthorized: boolean;
  isTermsAccepted: boolean;
  isContentVisible: boolean;
  isPasswordInputVisible: boolean;
  isModalOpen: boolean;
  isSuccessModalOpen: boolean;
  isMessageVisible: boolean;
  errorMessage: string;
  isDataAuthorizedError: string;
  isTermsAcceptedError: string;
  phoneNumber: string;
  phoneNumberError: string;
};

type Props = {
  t: TFunction;
};

type EFontWeightType = "normal" | "bold" | "lighter";

const useMagnetoAlertJobModal = ({ t }: Props) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const { selectedJob } = useContext(JobsContext);
  const hasExecutedRef = useRef(false);
  const [modalState, setModalState] = useState<ModalState>({
    email: "",
    password: "",
    loading: false,
    isDataAuthorized: false,
    isTermsAccepted: false,
    isPasswordInputVisible: false,
    isContentVisible: false,
    isModalOpen: false,
    isSuccessModalOpen: false,
    isMessageVisible: false,
    errorMessage: "",
    isDataAuthorizedError: "",
    isTermsAcceptedError: "",
    phoneNumber: "",
    phoneNumberError: ""
  });
  const setModalField = useCallback((field: keyof ModalState, value: any) => {
    setModalState((prev) => ({ ...prev, [field]: value }));
  }, []);

  const { handleLogin } = useLogin({
    t,
    setErrorMessage: (msg) => setModalField("errorMessage", msg),
    setLoading: (loading) => setModalField("loading", loading)
  });

  const isEmailValid = isValidEmail(modalState.email);
  const isPhoneNumberValid = isValidPhoneNumber(modalState.phoneNumber, countryCode.toUpperCase());

  const handleCloseModal = useCallback(() => {
    setModalState((prev) => ({
      ...prev,
      isModalOpen: false,
      isSuccessModalOpen: false
    }));
  }, [setModalState]);

  const toggleState = useCallback(
    (key: keyof typeof modalState) => {
      setModalState((prev) => ({
        ...prev,
        [key]: !prev[key],
        ...(key === "isDataAuthorized" && { isDataAuthorizedError: "" }),
        ...(key === "isTermsAccepted" && { isTermsAcceptedError: "" }),
        ...(key === "errorMessage" && { errorMessage: "" })
      }));
    },
    [setModalState]
  );

  const handleAlertCreationResult = useCallback(
    (result: boolean) => {
      if (result) {
        setModalState((prev) => ({
          ...prev,
          isModalOpen: false,
          isSuccessModalOpen: true
        }));
      } else {
        handleCloseModal();
        notification.error({
          message: t("jobOffers:alert.createAlertError").toString(),
          description: t("jobOffers:alert.createAlertErrorMessage").toString(),
          duration: 3
        });
      }
    },
    [t, handleCloseModal]
  );

  const processCreateAlert = useCallback(async () => {
    try {
      if (!selectedJob?.id) return false;

      const phoneData = modalState.phoneNumber
        ? {
            phone: modalState.phoneNumber,
            countryCode: convertCountryCodeToDialCode(countryCode.toUpperCase())
          }
        : {};

      const result = user?.id
        ? await MimirRepository.createAlert({
            vacantId: selectedJob.id,
            userId: user.id
          })
        : await MimirRepository.createPublicAlert({
            vacantId: selectedJob.id,
            email: modalState.email,
            ...(modalState.phoneNumber && phoneData)
          });

      handleAlertCreationResult(!!result);
      hasExecutedRef.current = !!result;
      return result;
    } catch (error) {
      handleAlertCreationResult(false);
      return false;
    }
  }, [modalState.email, modalState.phoneNumber, selectedJob?.id, user, handleAlertCreationResult]);

  const handleOpenModal = useCallback(() => {
    if (hasExecutedRef.current) return setModalField("isSuccessModalOpen", true);
    if (isAuthenticated) return processCreateAlert();
    setModalField("isModalOpen", true);
  }, [isAuthenticated, processCreateAlert, setModalField]);

  useEffect(() => {
    if (modalState.isPasswordInputVisible) {
      setModalField("isMessageVisible", true);
      const timer = setTimeout(() => setModalField("isMessageVisible", false), 3000);
      return () => clearTimeout(timer);
    }
  }, [modalState.isPasswordInputVisible, setModalField]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authSuccess = urlParams.get("authSuccess");
    if (authSuccess !== "true" || !isAuthenticated || hasExecutedRef.current) return;
    hasExecutedRef.current = true;
    setTimeout(() => {
      processCreateAlert().then((success) => {
        if (!success) hasExecutedRef.current = false;
        urlParams.delete("authSuccess");
        window.history.replaceState({}, "", `${window.location.pathname}?${urlParams.toString()}`);
      });
    }, 3000);
  }, [isAuthenticated, processCreateAlert]);

  const handleCheckSubmit = useCallback(async () => {
    setModalField("loading", true);
    try {
      if (!modalState.isDataAuthorized)
        throw { key: "isDataAuthorizedError", message: t("registration:form.required.field") };
      if (!modalState.isTermsAccepted)
        throw { key: "isTermsAcceptedError", message: t("registration:form.required.field") };
      if (!isAuthenticated) {
        const emailExists = await CandidateRepository.verifyEmail(modalState.email);
        if (!emailExists) return setModalField("isPasswordInputVisible", true);
      }
      const alertCreated = await processCreateAlert();
      if (!alertCreated) throw { message: t("jobOffers:alert.createAlertError") };
    } catch (error: any) {
      setModalField(error.key, error.message);
    } finally {
      setModalField("loading", false);
    }
  }, [
    modalState.isDataAuthorized,
    modalState.isTermsAccepted,
    modalState.email,
    isAuthenticated,
    processCreateAlert,
    t,
    setModalField
  ]);

  const isButtonDisabled = useCallback(() => {
    if (modalState.loading) return true;
    if (!modalState.email || !isEmailValid) return true;
    if (modalState.phoneNumber && !isPhoneNumberValid) return true;
    if (modalState.isPasswordInputVisible && !modalState.password) return true;
    return false;
  }, [
    modalState.loading,
    modalState.email,
    isEmailValid,
    modalState.phoneNumber,
    isPhoneNumberValid,
    modalState.isPasswordInputVisible,
    modalState.password
  ]);

  const formProps = useMemo(() => {
    return {
      modalState,
      isButtonDisabled
    };
  }, [modalState, isButtonDisabled]);

  const settersProps = useMemo(() => {
    return {
      setModalField,
      isEmailValid,
      isPhoneNumberValid,
      toggleState
    };
  }, [isEmailValid, isPhoneNumberValid, toggleState, setModalField]);

  const actionsProps = useMemo(() => {
    return {
      handleCheckSubmit,
      handleLogin,
      handleCloseModal,
      handleOpenModal
    };
  }, [handleCheckSubmit, handleCloseModal, handleLogin, handleOpenModal]);

  const staticTexts = useMemo(
    () => [
      { value: t("jobOffers:alert.noMoreApplications"), fontWeight: "bold" as EFontWeightType, lineBreak: true },
      {
        value: t("jobOffers:alert.noMoreApplicationsSubtitle"),
        fontWeight: "normal" as EFontWeightType,
        lineBreak: true
      }
    ],
    [t]
  );

  return { formProps, settersProps, actionsProps, staticTexts };
};

export default useMagnetoAlertJobModal;
